import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  AdminSession,
  PasswordChange,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  PasswordVisibility,
} from '../../../models/password-visibility.model';

import {
  CustomValidators,
} from '../../../validators/form.validator';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  AuthState,
} from '../../../store/auth/auth.state';

import {
  ChangePassword,
} from '../../../store/auth/auth.actions';

import { ActivatedRoute, ParamMap } from '@angular/router'

import { SigninService } from '../../../services/signin.service';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {MatSnackBar} from '@angular/material/snack-bar';
@Component({
  selector: 'nym-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // animations: ANIMATIONS,
  providers: [ProblemHandler],
})
export class PasswordChangeComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;

  private _session: AdminSession;
  private _username: string;
  private _password: string;
  private _resetId: string = '';
  public loading : boolean = false;

  @Select(AuthState.problem)
  public _problem$: Observable<Problem>;

  @Select(AuthState.authenticating)
  public authenticating$: Observable<boolean>;

  public get username(): string {
    return this._username;
  }

  public passwordMode: PasswordVisibility = PasswordVisibility.Hidden;
  public passwordMode1: PasswordVisibility = PasswordVisibility.Hidden;

  public form: FormGroup;

  public get password(): AbstractControl {
    return this.form.get('password');
  }

  public get confirm(): AbstractControl {
    return this.form.get('confirm');
  }

  passwordPolicy: any;
  passwordPolicyRules: string[];
  passwordPolicyError = '';

  constructor(
    private _fb: FormBuilder,
    private _store: Store,
    private _ph: ProblemHandler,
    private _route: ActivatedRoute,
    private _signinService:SigninService,
    private _nc: MatSnackBar,
    private chRef: ChangeDetectorRef
  ) {
    this._resetId = this._route.snapshot.paramMap.get('rid');
    if(this._resetId){
      this.checkExpiration(this._resetId)
    }
   
    this._session = this._store.selectSnapshot(AuthState.session);
    this._username = this._store.selectSnapshot(AuthState.username);
    this._password = this._store.selectSnapshot(AuthState.password);

    this.form = this._fb.group(
      {
        password: ['', [Validators.required]],
        confirm: ['', [Validators.required]],
      },
      {
        validator: CustomValidators.match('password', 'confirm'),
      },
    );
  }

  public ngOnInit(): void {
    this.getPasswordPolicy();

    this._subscription = this._problem$.subscribe((problem) => {
      this._ph.handle(problem, this.form, [{ from: 'newPassword', to: 'password' }]);
    });
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  validatePassword(): boolean {
    this.passwordPolicyError = undefined;
    let isPasswordValid = true;
    const passwordStr = this.form.get('password').value;
    this.passwordPolicy.rules.forEach((rule: any) => {
      let regexRule = new RegExp(rule.regex);
      if (!regexRule.test(passwordStr)) {
        this.passwordPolicyError = rule.description + ' required';
        isPasswordValid = false;
      }
    });

    return isPasswordValid;
  }

  public submit(): void {
    if (!this.validatePassword()) {
      return;
    }

    this.loading = true;

    if (!this.form.valid){
      this.loading = false;
      return;
    }
      
    if(this._resetId){
      const passwordChange = new PasswordChange({
      oldPassword: '',
      newPassword: this.form.controls['password'].value,
      
    });
      this._store.dispatch(
        new ChangePassword({
          id: this._resetId,
          passwordChange: passwordChange,
        }),
        );
    } else {
      
      const passwordChange = new PasswordChange({
        oldPassword: this._password,
        newPassword: this.form.controls['password'].value,
      });
      this._store.dispatch(
        new ChangePassword({
          id: this._session.id,
          passwordChange: passwordChange,
        }),
      );
    }


  }

  public togglePassword(ele): void {
    // this.passwordMode.toggle();
    ele.toggle();
  }
  
  public checkExpiration(id): void{
    const partial_url = window.location.href.split('/')[3]
    if(id){
      this._signinService.checkKeyExpiration(id).subscribe((data) => {
        console.log(data);
        // this._nc.show(new Notification('Please Check your mail. Password reset request send to you mail'));
      }, (error) =>{
        console.log(error);
        // if(error.error.title == 'Unauthorized'){
          this._nc.open('The activation link was expired','', {duration: 5000});
          this._store.dispatch(new Navigate([partial_url+'/account/signin']));
        // } 
      });
    }
  }

  getPasswordPolicy(): void {
    this._signinService.getPasswordPolicy().subscribe(
      policy => {
        this.passwordPolicy = policy;
        const rules = policy.rules.map((rule: any) => rule.description);
        this.passwordPolicyRules = [...rules];
        this.chRef.detectChanges();
      }
    )
  }
}
