import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  Menu,
} from '../../models/menu.model';
import { CallCenterService } from '../../../../../../../../dashboard/front-end/call-center/src/lib/core/services/call-center.service';
import { Store } from '@ngxs/store';
import { SignOut } from '../../../../../../../../nymos/auth/src/lib/store/auth/auth.actions';
import { MenuItem } from '../../models/menu-item.model';

@Component({
  selector: 'thm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {

  @HostBinding('class.thm-menu')
  protected get classes(): boolean { return true; }

  public permissions: string[] = [];

  @Input()
  public menu: Menu;

  menuItems: MenuItem[] = [
    {
      title: 'Dashboard',
      icon: 'menuicon:dashboard',
      url: '/',
      type: 'item',
      scopes: 'p:dashboard'
    },
    {
      title: 'Fintechs',
      icon: 'menuicon:fintechs',
      url: '/fintechs',
      type: 'item',
      scopes: 'p:fintechs'
    },
    {
      title: 'Reports',
      icon: 'menuicon:reports',
      url: '/reports',
      type: 'item',
      scopes: 'p:reports'
    },
    {
      title: 'Card Management',
      icon: 'menuicon:card-mgmt',
      url: '/card-management',
      type: 'item',
      scopes: 'p:card-mgmt'
    },
    {
      title: 'Users',
      icon: 'menuicon:users',
      url: '/users',
      type: 'item',
      scopes: 'p:users'
    },
    {
      title: 'Tenant Management',
      icon: 'menuicon:tenant-mgmt',
      url: '/tenant',
      type: 'item',
      scopes: 'p:tenant-mgmt'
    },
    {
      title: 'Product Management',
      icon: 'menuicon:product-mgmt',
      type: 'item',
      scopes: 'p:productmgmt',
      children: [
        { title: 'Card Products', url: '/product-management', scopes: 'p:productmgmt:cardproducts' },
        { title: 'Balance Limits', url: '/product-management/balance-limits', scopes: 'p:productmgmt:balancelimits' },
        { title: 'Fee Plans', url: '/product-management/fee-plans', scopes: 'p:productmgmt:feeplans' },
        { title: 'Velocity Limits', url: '/product-management/velocity-limits', scopes: 'p:productmgmt:velocitylimits' },
        { title: 'Bulk Renewal', url: '/product-management/bulk-renewal', scopes: 'p:productmgmt:bulkrenewal' },
      ]
    },
    {
      title: 'Access Management',
      icon: 'menuicon:access-mgmt',
      type: 'item',
      scopes: 'p:accessmgmt',
      children: [
        { title: 'Roles', url: '/access-management/roles', scopes: 'p:accessmgmt:roles' },
        { title: 'Users', url: '/access-management/users', scopes: 'p:accessmgmt:admins' }
      ]
    },
    {
      title: 'Tickets',
      icon: 'menuicon:tickets',
      type: 'item',
      scopes: 'p:users',
      url: '/tickets'
    },
    {
      title: 'Support',
      icon: 'menuicon:support',
      url: '/support',
      type: 'item',
      scopes: 'p:support'
    },
    {
      title: 'Call Center',
      icon: 'menuicon:call-center',
      url: '/call-center',
      action: 'resetSearchQuery',
      type: 'item',
      scopes: 'p:call-center'
    }
  ];

  @Output('navigation') // tslint:disable-line:no-output-rename
  public navigationEmitter: EventEmitter<any> = new EventEmitter();

  filteredMenuItems = [];

  constructor(private callCenterService: CallCenterService, private _store: Store) { }

  public ngOnInit(): void {
    const scopes = localStorage['profile.scopes'] ? JSON.parse(localStorage['profile.scopes']) : [];
    this.permissions = scopes;

    this.filteredMenuItems = this.filterMenuItemByPermission(this.menuItems, scopes);
  }

  public onNavigation(action: any): void {
    this.navigationEmitter.emit(action);
  }

  public filterMenuItemByPermission(menuItems: MenuItem[], scopes: string[]) {
    const matches = [];
    const queue = [...menuItems];

    while (queue.length) {
      const item = queue.shift();
      let {children, ...newItem} = item;

      if (item.children) {
        const children = item.children.filter((child: MenuItem) => child.scopes && scopes.includes(String(child.scopes)));
        if (children.length) {
          newItem['children'] = children;
        }
      }
      if (item.scopes && scopes.includes(String(item.scopes))) {
        matches.push(newItem);
      }
    }

    return matches;
  }

  public resetSearchQuery() {
    this.callCenterService.setSearchResultQuery(null);
  }

  public logoutUser(): void {
    this._store.dispatch(new SignOut());
    window.localStorage.clear();
  }
}
