import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  RouterModule,
} from '@angular/router';

import {
  MatRippleModule,
} from '@angular/material/core';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  MatListModule,
} from '@angular/material/list';

import {
  MenuComponent,
} from './containers/menu/menu.component';

import {
  MenuItemComponent,
} from './components/menu-item/menu-item.component';

import {
  MenuGroupComponent,
} from './components/menu-group/menu-group.component';
import { MatExpansionModule, MatMenuModule } from '@angular/material';


export const MATERIAL_MODULES = [
  MatIconModule,
  MatListModule,
  MatRippleModule,
  MatExpansionModule
];

@NgModule({
  declarations: [
    MenuComponent,
    MenuItemComponent,
    MenuGroupComponent
  ],

  imports: [
    ...MATERIAL_MODULES,
    FlexLayoutModule,
    RouterModule,
    CommonModule,
    MatMenuModule
  ],

  exports: [
    MenuComponent,
  ],
})
export class ThemeComponentsMenuModule { }
