import {
    Optional,
    Provider,
    SkipSelf,
} from '@angular/core';

import {
    Injectable,
} from '@angular/core';

import {
    DomSanitizer,
} from '@angular/platform-browser';

import {
    MatIconRegistry,
} from '@angular/material/icon';


const ASSETS_FOLDER = 'assets/theme/';

@Injectable()
export class ThemeAssets {

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
    ) {
        iconRegistry.addSvgIconInNamespace('theme', 'empty-box', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'empty-box.svg'));
        iconRegistry.addSvgIconInNamespace('creditcard', 'default', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'creditcard/default.svg'));
        iconRegistry.addSvgIconInNamespace('giftcard', 'default', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'giftcard/default.svg'));
        iconRegistry.addSvgIconInNamespace('travelcard', 'default', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'travelcard/default.svg'));
        iconRegistry.addSvgIconInNamespace('travelcard', 'visa-gold', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'travelcard/visa-gold.svg'));

        iconRegistry.addSvgIconInNamespace('menuicon', 'dashboard', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'menu-icons/dashboard.svg'));
        iconRegistry.addSvgIconInNamespace('menuicon', 'fintechs', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'menu-icons/fintechs.svg'));
        iconRegistry.addSvgIconInNamespace('menuicon', 'reports', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'menu-icons/reports.svg'));
        iconRegistry.addSvgIconInNamespace('menuicon', 'card-mgmt', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'menu-icons/card-management.svg'));
        iconRegistry.addSvgIconInNamespace('menuicon', 'users', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'menu-icons/users.svg'));
        iconRegistry.addSvgIconInNamespace('menuicon', 'tenant-mgmt', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'menu-icons/tenant-management.svg'));
        iconRegistry.addSvgIconInNamespace('menuicon', 'support', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'menu-icons/support.svg'));
        iconRegistry.addSvgIconInNamespace('menuicon', 'call-center', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'menu-icons/call-center.svg'));
        iconRegistry.addSvgIconInNamespace('menuicon', 'access-mgmt', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'menu-icons/access-management.svg'));
        iconRegistry.addSvgIconInNamespace('menuicon', 'roles', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'menu-icons/role-management.svg'));
        iconRegistry.addSvgIconInNamespace('menuicon', 'product-mgmt', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'menu-icons/product-management.svg'));
        iconRegistry.addSvgIconInNamespace('menuicon', 'tickets', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'menu-icons/tickets.svg'));
    }

}


export const themeAssetsFactory = (
    service: ThemeAssets,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
) => {
    return service || new ThemeAssets(iconRegistry, sanitizer);
};

export const themeAssetsProvider: Provider = {
    provide: ThemeAssets,
    useFactory: themeAssetsFactory,
    deps: [
        [new Optional(), new SkipSelf(), ThemeAssets],
        [MatIconRegistry],
        [DomSanitizer],
    ],
};
