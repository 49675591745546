import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {
  private currencies = [
    {
      country: 'US Dollar',
      currencyCode: 'USD',
      currencySymbol: '$',
      decimalDigits: 2
    },
    {
      country: 'Canadian Dollar',
      currencyCode: 'CAD',
      currencySymbol: 'CA$',
      decimalDigits: 2
    },
    {
      country: 'Euro',
      currencyCode: 'EUR',
      currencySymbol: '€',
      decimalDigits: 2
    },
    {
      country: 'United Arab Emirates Dirham',
      currencyCode: 'AED',
      currencySymbol: 'AED',
      decimalDigits: 2
    },
    {
      country: 'Afghan Afghani',
      currencyCode: 'AFN',
      currencySymbol: 'Af',
      decimalDigits: 0
    },
    {
      country: 'Albanian Lek',
      currencyCode: 'ALL',
      currencySymbol: 'ALL',
      decimalDigits: 0
    },
    {
      country: 'Armenian Dram',
      currencyCode: 'AMD',
      currencySymbol: 'AMD',
      decimalDigits: 0
    },
    {
      country: 'Argentine Peso',
      currencyCode: 'ARS',
      currencySymbol: 'AR$',
      decimalDigits: 2
    },
    {
      country: 'Australian Dollar',
      currencyCode: 'AUD',
      currencySymbol: 'AU$',
      decimalDigits: 2
    },
    {
      country: 'Azerbaijani Manat',
      currencyCode: 'AZN',
      currencySymbol: 'man.',
      decimalDigits: 2
    },
    {
      country: 'Bosnia-Herzegovina Convertible Mark',
      currencyCode: 'BAM',
      currencySymbol: 'KM',
      decimalDigits: 2
    },
    {
      country: 'Bangladeshi Taka',
      currencyCode: 'BDT',
      currencySymbol: 'Tk',
      decimalDigits: 2
    },
    {
      country: 'Bulgarian Lev',
      currencyCode: 'BGN',
      currencySymbol: 'BGN',
      decimalDigits: 2
    },
    {
      country: 'Bahraini Dinar',
      currencyCode: 'BHD',
      currencySymbol: 'BD',
      decimalDigits: 3
    },
    {
      country: 'Burundian Franc',
      currencyCode: 'BIF',
      currencySymbol: 'FBu',
      decimalDigits: 0
    },
    {
      country: 'Brunei Dollar',
      currencyCode: 'BND',
      currencySymbol: 'BN$',
      decimalDigits: 2
    },
    {
      country: 'Bolivian Boliviano',
      currencyCode: 'BOB',
      currencySymbol: 'Bs',
      decimalDigits: 2
    },
    {
      country: 'Brazilian Real',
      currencyCode: 'BRL',
      currencySymbol: 'R$',
      decimalDigits: 2
    },
    {
      country: 'Botswanan Pula',
      currencyCode: 'BWP',
      currencySymbol: 'BWP',
      decimalDigits: 2
    },
    {
      country: 'Belarusian Ruble',
      currencyCode: 'BYN',
      currencySymbol: 'Br',
      decimalDigits: 2
    },
    {
      country: 'Belize Dollar',
      currencyCode: 'BZD',
      currencySymbol: 'BZ$',
      decimalDigits: 2
    },
    {
      country: 'Congolese Franc',
      currencyCode: 'CDF',
      currencySymbol: 'CDF',
      decimalDigits: 2
    },
    {
      country: 'Swiss Franc',
      currencyCode: 'CHF',
      currencySymbol: 'CHF',
      decimalDigits: 2
    },
    {
      country: 'Chilean Peso',
      currencyCode: 'CLP',
      currencySymbol: 'CL$',
      decimalDigits: 0
    },
    {
      country: 'Chinese Yuan',
      currencyCode: 'CNY',
      currencySymbol: 'CN¥',
      decimalDigits: 2
    },
    {
      country: 'Colombian Peso',
      currencyCode: 'COP',
      currencySymbol: 'CO$',
      decimalDigits: 0
    },
    {
      country: 'Costa Rican Colón',
      currencyCode: 'CRC',
      currencySymbol: '₡',
      decimalDigits: 0
    },
    {
      country: 'Cape Verdean Escudo',
      currencyCode: 'CVE',
      currencySymbol: 'CV$',
      decimalDigits: 2
    },
    {
      country: 'Czech Republic Koruna',
      currencyCode: 'CZK',
      currencySymbol: 'Kč',
      decimalDigits: 2
    },
    {
      country: 'Djiboutian Franc',
      currencyCode: 'DJF',
      currencySymbol: 'Fdj',
      decimalDigits: 0
    },
    {
      country: 'Danish Krone',
      currencyCode: 'DKK',
      currencySymbol: 'Dkr',
      decimalDigits: 2
    },
    {
      country: 'Dominican Peso',
      currencyCode: 'DOP',
      currencySymbol: 'RD$',
      decimalDigits: 2
    },
    {
      country: 'Algerian Dinar',
      currencyCode: 'DZD',
      currencySymbol: 'DA',
      decimalDigits: 2
    },
    {
      country: 'Estonian Kroon',
      currencyCode: 'EEK',
      currencySymbol: 'Ekr',
      decimalDigits: 2
    },
    {
      country: 'Egyptian Pound',
      currencyCode: 'EGP',
      currencySymbol: 'EGP',
      decimalDigits: 2
    },
    {
      country: 'Eritrean Nakfa',
      currencyCode: 'ERN',
      currencySymbol: 'Nfk',
      decimalDigits: 2
    },
    {
      country: 'Ethiopian Birr',
      currencyCode: 'ETB',
      currencySymbol: 'Br',
      decimalDigits: 2
    },
    {
      country: 'British Pound Sterling',
      currencyCode: 'GBP',
      currencySymbol: '£',
      decimalDigits: 2
    },
    {
      country: 'Georgian Lari',
      currencyCode: 'GEL',
      currencySymbol: 'GEL',
      decimalDigits: 2
    },
    {
      country: 'Ghanaian Cedi',
      currencyCode: 'GHS',
      currencySymbol: 'GH₵',
      decimalDigits: 2
    },
    {
      country: 'Guinean Franc',
      currencyCode: 'GNF',
      currencySymbol: 'FG',
      decimalDigits: 0
    },
    {
      country: 'Guatemalan Quetzal',
      currencyCode: 'GTQ',
      currencySymbol: 'GTQ',
      decimalDigits: 2
    },
    {
      country: 'Hong Kong Dollar',
      currencyCode: 'HKD',
      currencySymbol: 'HK$',
      decimalDigits: 2
    },
    {
      country: 'Honduran Lempira',
      currencyCode: 'HNL',
      currencySymbol: 'HNL',
      decimalDigits: 2
    },
    {
      country: 'Croatian Kuna',
      currencyCode: 'HRK',
      currencySymbol: 'kn',
      decimalDigits: 2
    },
    {
      country: 'Hungarian Forint',
      currencyCode: 'HUF',
      currencySymbol: 'Ft',
      decimalDigits: 0
    },
    {
      country: 'Indonesian Rupiah',
      currencyCode: 'IDR',
      currencySymbol: 'Rp',
      decimalDigits: 0
    },
    {
      country: 'Israeli New Sheqel',
      currencyCode: 'ILS',
      currencySymbol: '₪',
      decimalDigits: 2
    },
    {
      country: 'Indian Rupee',
      currencyCode: 'INR',
      currencySymbol: 'Rs',
      decimalDigits: 2
    },
    {
      country: 'Iraqi Dinar',
      currencyCode: 'IQD',
      currencySymbol: 'IQD',
      decimalDigits: 0
    },
    {
      country: 'Iranian Rial',
      currencyCode: 'IRR',
      currencySymbol: 'IRR',
      decimalDigits: 0
    },
    {
      country: 'Icelandic Króna',
      currencyCode: 'ISK',
      currencySymbol: 'Ikr',
      decimalDigits: 0
    },
    {
      country: 'Jamaican Dollar',
      currencyCode: 'JMD',
      currencySymbol: 'J$',
      decimalDigits: 2
    },
    {
      country: 'Jordanian Dinar',
      currencyCode: 'JOD',
      currencySymbol: 'JD',
      decimalDigits: 3
    },
    {
      country: 'Japanese Yen',
      currencyCode: 'JPY',
      currencySymbol: '¥',
      decimalDigits: 0
    },
    {
      country: 'Kenyan Shilling',
      currencyCode: 'KES',
      currencySymbol: 'Ksh',
      decimalDigits: 2
    },
    {
      country: 'Cambodian Riel',
      currencyCode: 'KHR',
      currencySymbol: 'KHR',
      decimalDigits: 2
    },
    {
      country: 'Comorian Franc',
      currencyCode: 'KMF',
      currencySymbol: 'CF',
      decimalDigits: 0
    },
    {
      country: 'South Korean Won',
      currencyCode: 'KRW',
      currencySymbol: '₩',
      decimalDigits: 0
    },
    {
      country: 'Kuwaiti Dinar',
      currencyCode: 'KWD',
      currencySymbol: 'KD',
      decimalDigits: 3
    },
    {
      country: 'Kazakhstani Tenge',
      currencyCode: 'KZT',
      currencySymbol: 'KZT',
      decimalDigits: 2
    },
    {
      country: 'Lebanese Pound',
      currencyCode: 'LBP',
      currencySymbol: 'L.L.',
      decimalDigits: 0
    },
    {
      country: 'Sri Lankan Rupee',
      currencyCode: 'LKR',
      currencySymbol: 'SLRs',
      decimalDigits: 2
    },
    {
      country: 'Lithuanian Litas',
      currencyCode: 'LTL',
      currencySymbol: 'Lt',
      decimalDigits: 2
    },
    {
      country: 'Latvian Lats',
      currencyCode: 'LVL',
      currencySymbol: 'Ls',
      decimalDigits: 2
    },
    {
      country: 'Libyan Dinar',
      currencyCode: 'LYD',
      currencySymbol: 'LD',
      decimalDigits: 3
    },
    {
      country: 'Moroccan Dirham',
      currencyCode: 'MAD',
      currencySymbol: 'MAD',
      decimalDigits: 2
    },
    {
      country: 'Moldovan Leu',
      currencyCode: 'MDL',
      currencySymbol: 'MDL',
      decimalDigits: 2
    },
    {
      country: 'Malagasy Ariary',
      currencyCode: 'MGA',
      currencySymbol: 'MGA',
      decimalDigits: 0
    },
    {
      country: 'Macedonian Denar',
      currencyCode: 'MKD',
      currencySymbol: 'MKD',
      decimalDigits: 2
    },
    {
      country: 'Myanma Kyat',
      currencyCode: 'MMK',
      currencySymbol: 'MMK',
      decimalDigits: 0
    },
    {
      country: 'Macanese Pataca',
      currencyCode: 'MOP',
      currencySymbol: 'MOP$',
      decimalDigits: 2
    },
    {
      country: 'Mauritian Rupee',
      currencyCode: 'MUR',
      currencySymbol: 'MURs',
      decimalDigits: 0
    },
    {
      country: 'Mexican Peso',
      currencyCode: 'MXN',
      currencySymbol: 'MX$',
      decimalDigits: 2
    },
    {
      country: 'Malaysian Ringgit',
      currencyCode: 'MYR',
      currencySymbol: 'RM',
      decimalDigits: 2
    },
    {
      country: 'Mozambican Metical',
      currencyCode: 'MZN',
      currencySymbol: 'MTn',
      decimalDigits: 2
    },
    {
      country: 'Namibian Dollar',
      currencyCode: 'NAD',
      currencySymbol: 'N$',
      decimalDigits: 2
    },
    {
      country: 'Nigerian Naira',
      currencyCode: 'NGN',
      currencySymbol: '₦',
      decimalDigits: 2
    },
    {
      country: 'Nicaraguan Córdoba',
      currencyCode: 'NIO',
      currencySymbol: 'C$',
      decimalDigits: 2
    },
    {
      country: 'Norwegian Krone',
      currencyCode: 'NOK',
      currencySymbol: 'Nkr',
      decimalDigits: 2
    },
    {
      country: 'Nepalese Rupee',
      currencyCode: 'NPR',
      currencySymbol: 'NPRs',
      decimalDigits: 2
    },
    {
      country: 'New Zealand Dollar',
      currencyCode: 'NZD',
      currencySymbol: 'NZ$',
      decimalDigits: 2
    },
    {
      country: 'Omani Rial',
      currencyCode: 'OMR',
      currencySymbol: 'OMR',
      decimalDigits: 3
    },
    {
      country: 'Panamanian Balboa',
      currencyCode: 'PAB',
      currencySymbol: 'B/.',
      decimalDigits: 2
    },
    {
      country: 'Peruvian Nuevo Sol',
      currencyCode: 'PEN',
      currencySymbol: 'S/.',
      decimalDigits: 2
    },
    {
      country: 'Philippine Peso',
      currencyCode: 'PHP',
      currencySymbol: '₱',
      decimalDigits: 2
    },
    {
      country: 'Pakistani Rupee',
      currencyCode: 'PKR',
      currencySymbol: 'PKRs',
      decimalDigits: 0
    },
    {
      country: 'Polish Zloty',
      currencyCode: 'PLN',
      currencySymbol: 'zł',
      decimalDigits: 2
    },
    {
      country: 'Paraguayan Guarani',
      currencyCode: 'PYG',
      currencySymbol: '₲',
      decimalDigits: 0
    },
    {
      country: 'Qatari Rial',
      currencyCode: 'QAR',
      currencySymbol: 'QR',
      decimalDigits: 2
    },
    {
      country: 'Romanian Leu',
      currencyCode: 'RON',
      currencySymbol: 'RON',
      decimalDigits: 2
    },
    {
      country: 'Serbian Dinar',
      currencyCode: 'RSD',
      currencySymbol: 'din.',
      decimalDigits: 0
    },
    {
      country: 'Russian Ruble',
      currencyCode: 'RUB',
      currencySymbol: 'RUB',
      decimalDigits: 2
    },
    {
      country: 'Rwandan Franc',
      currencyCode: 'RWF',
      currencySymbol: 'RWF',
      decimalDigits: 0
    },
    {
      country: 'Saudi Riyal',
      currencyCode: 'SAR',
      currencySymbol: 'SR',
      decimalDigits: 2
    },
    {
      country: 'Sudanese Pound',
      currencyCode: 'SDG',
      currencySymbol: 'SDG',
      decimalDigits: 2
    },
    {
      country: 'Swedish Krona',
      currencyCode: 'SEK',
      currencySymbol: 'Skr',
      decimalDigits: 2
    },
    {
      country: 'Singapore Dollar',
      currencyCode: 'SGD',
      currencySymbol: 'S$',
      decimalDigits: 2
    },
    {
      country: 'Somali Shilling',
      currencyCode: 'SOS',
      currencySymbol: 'Ssh',
      decimalDigits: 0
    },
    {
      country: 'Syrian Pound',
      currencyCode: 'SYP',
      currencySymbol: 'SY£',
      decimalDigits: 0
    },
    {
      country: 'Thai Baht',
      currencyCode: 'THB',
      currencySymbol: '฿',
      decimalDigits: 2
    },
    {
      country: 'Tunisian Dinar',
      currencyCode: 'TND',
      currencySymbol: 'DT',
      decimalDigits: 3
    },
    {
      country: 'Tongan Paʻanga',
      currencyCode: 'TOP',
      currencySymbol: 'T$',
      decimalDigits: 2
    },
    {
      country: 'Turkish Lira',
      currencyCode: 'TRY',
      currencySymbol: 'TL',
      decimalDigits: 2
    },
    {
      country: 'Trinidad and Tobago Dollar',
      currencyCode: 'TTD',
      currencySymbol: 'TT$',
      decimalDigits: 2
    },
    {
      country: 'New Taiwan Dollar',
      currencyCode: 'TWD',
      currencySymbol: 'NT$',
      decimalDigits: 2
    },
    {
      country: 'Tanzanian Shilling',
      currencyCode: 'TZS',
      currencySymbol: 'TSh',
      decimalDigits: 0
    },
    {
      country: 'Ukrainian Hryvnia',
      currencyCode: 'UAH',
      currencySymbol: '₴',
      decimalDigits: 2
    },
    {
      country: 'Ugandan Shilling',
      currencyCode: 'UGX',
      currencySymbol: 'USh',
      decimalDigits: 0
    },
    {
      country: 'Uruguayan Peso',
      currencyCode: 'UYU',
      currencySymbol: '$U',
      decimalDigits: 2
    },
    {
      country: 'Uzbekistan Som',
      currencyCode: 'UZS',
      currencySymbol: 'UZS',
      decimalDigits: 0
    },
    {
      country: 'Venezuelan Bolívar',
      currencyCode: 'VEF',
      currencySymbol: 'Bs.F.',
      decimalDigits: 2
    },
    {
      country: 'Vietnamese Dong',
      currencyCode: 'VND',
      currencySymbol: '₫',
      decimalDigits: 0
    },
    {
      country: 'CFA Franc BEAC',
      currencyCode: 'XAF',
      currencySymbol: 'FCFA',
      decimalDigits: 0
    },
    {
      country: 'CFA Franc BCEAO',
      currencyCode: 'XOF',
      currencySymbol: 'CFA',
      decimalDigits: 0
    },
    {
      country: 'Yemeni Rial',
      currencyCode: 'YER',
      currencySymbol: 'YR',
      decimalDigits: 0
    },
    {
      country: 'South African Rand',
      currencyCode: 'ZAR',
      currencySymbol: 'R',
      decimalDigits: 2
    },
    {
      country: 'Zambian Kwacha',
      currencyCode: 'ZMK',
      currencySymbol: 'ZK',
      decimalDigits: 0
    },
    {
      country: 'Zimbabwean Dollar',
      currencyCode: 'ZWL',
      currencySymbol: 'ZWL$',
      decimalDigits: 0
    }
  ];

  constructor() {}

  getCurrencies() {
    return this.currencies;
  }
}
