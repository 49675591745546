import { Inject, Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import {
    Store,
} from '@ngxs/store';

import { MatSnackBar } from '@angular/material/snack-bar';

import {
    Navigate,
} from '@ngxs/router-plugin';
import { NYM_DISCOVERY_SERVICE } from '@nymos/discovery';
import { NON_PROXY_ENDPOINTS } from '../constants/non-proxy-endpoints.constant';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(
        private _nc: MatSnackBar,
        private _store: Store,
        @Inject(NYM_DISCOVERY_SERVICE) private serviceDiscovery
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var token = localStorage.getItem('auth.token');

        const apiPath = this.serviceDiscovery.getUrl() + 'v1/';
        const isNonProxyEndpoint = NON_PROXY_ENDPOINTS.some(endpoint => request.url.includes(endpoint));
        let proxyPath = request.url.replace(apiPath, `${this.serviceDiscovery.getUrl()}nfront/v1/proxy/`);
        if (isNonProxyEndpoint) {
            proxyPath = request.url.replace(apiPath, `${this.serviceDiscovery.getUrl()}nfront/v1/`);
        }
        request = request.clone({ url: proxyPath });

        if (token != 'undefined') {
            var access_token = JSON.parse(token).access_token;
            var api_key = localStorage.getItem('apikey');
            if (request.headers.get('x-no-authorization') === undefined || request.headers.get('x-no-authorization') !== 'TRUE') {
                request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + access_token) });
            }

            if(request.headers.get('x-no-api-key') === undefined || request.headers.get('x-no-api-key') !== 'TRUE') {
                request = request.clone({ headers: request.headers.set('Apikey', api_key) });
            }
        }

        if (!request.headers.has('Content-Type')) {
            if (!(request.body instanceof FormData)) {
                request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
            }
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request)
            .pipe(catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401 && err.statusText == 'Unauthorized') {
                        this._nc.open('Login Token Expired.Please login again', '', { duration: 5000 });
                        var partial_url = window.localStorage.getItem('apikey')
                        this._store.dispatch(new Navigate([partial_url + '/account/signin']));
                    }
                }

                return throwError(err);
            }));

    }
}
